<template>
  <div class="text-left">
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else class="pt-4 mt-4">
      <!-- {{ balanceTotals }} -->

      <div  v-if="balanceTotals && Object.keys(balanceTotals).length > 0" class="row mb20">
              <div
              v-for="(item, index) in Object.keys(balanceTotals)"
              :key="`${index}pts`"
               class="col-sm-3  mb-1">
                  <div   :class="'bg-gradient-'+getColor(item)" class='stat-panel shadow  mb-0'>
                      <div class="stat-cell">
                          <v-icon scale="7.6" class="bg-icon" name="credit-card" flip="horizontal" inverse="false" color="white"/>
 
                          <!-- <i class="fas fa-money-check-alt bg-icon"></i> -->
                          <span class="text-xlg">$ {{ balanceTotals[item] || 0.0 }}</span><br>
                          <span class="text-lg">{{ item.replace("_", " ") || "Totals" }}</span><br>
                          <span class="btn btn-outline-light py-1 px-2 rounded-full" data-ajax="/financial-overview/unrequested">USD</span>
                      </div>
                  </div>
              </div>
              
              
          </div>

     
    </section> 
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Payments",
  computed: {
    ...mapState("payment", [
      "balanceTotals",
      "balancePayments",
      "loading",
      "balancePaymentsHeaders",
    ]),
  },

  methods: {
    ...mapActions("payment", ["_getBalancePayments"]),
    getColor(status) {
      let color = "success";
      if (status.includes("pending")) {
        color = "warning";
      } else if (status.includes("earned")) {
        color = "teal";
      } else if (status.includes("fines")) {
        color = "pink";
      } else if (status.includes("available")) {
        color = "info";
      } else {
        color = 'primary'
      }
      return color;
    },
  },

  mounted() {
    this._getBalancePayments().catch((err) =>
      console.log("error fetching payments", err)
    );
  },
};
</script>

<style scoped>
.v-alert {
  min-height: 120px;
  max-height: 120px;
  border-radius: 8px;
}
</style>
