<template>
  <div class="text-left">
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>
    <div class="my-2" v-else>
      <div class="">
        <h5 class="text-capitalize mb-2 font-weight-bold">
          Hello, {{ username || "" }}
        </h5>
       
        <!-- <BaseHeader :title="'Dashboard'"></BaseHeader> -->
        <div v-if="user.id != 100000" class="">
          <!-- writer and editor dashboard -->
          <div
            v-if="userType == userTypes.writer || userType == userTypes.editor"
          >
            <BalanceComponent />
          </div>

          <!-- admin dashboard -->

          <div v-else>
            <div class="row mb20">
                <div class="col-sm-3">
                    <div class="stat-panel bg-gradient-primary">
                        <div class="stat-cell">
                            <v-icon scale="6.6" class="bg-icon" name="users" flip="horizontal" inverse="false" color="white"/>

                            <!-- <i class="fas fa-money-check-alt bg-icon"></i> -->
                            <span class="text-xlg">{{dashboardData.row1 && dashboardData.row1.Clients
                            ? dashboardData.row1['Clients']
                            : '0'}}</span><br>
                            <span class="text-lg">Clients</span><br>
                            <router-link :to="{ name: 'Clients' }" data-ajax="/financial-overview/unrequested">View clients</router-link>
                        </div>
                    </div>
                </div>
                
                <div class="col-sm-3">
                    <div class="stat-panel bg-gradient-success">
                        <div class="stat-cell">
                          <v-icon scale="6.6" class="bg-icon" name="folder" flip="horizontal" inverse="false" color="white"/>
                            <span class="text-xlg">{{dashboardData.row1.Files || 0}}</span><br>
                            <span class="text-lg">Files</span><br>
                            <router-link :to="{ name: 'OrderListing', params: { status: 'approved' } }" data-ajax="/financial-overview/history"> View Approved Orders</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3">
                    <div class="stat-panel bg-gradient-warning">
                        <div class="stat-cell">
                          <v-icon scale="6.6" class="bg-icon" name="layer-group" flip="horizontal" inverse="false" color="white"/>
                            <span class="text-xlg">{{ dashboardData.row1['Pages Written'] || 0 }}</span><br>
                            <span class="text-lg">Pages Written</span><br>
                            <router-link :to="{ name: 'OrderListing', params: { status: 'completed' } }" data-ajax="/financial-overview/history">View Completed Orders</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3">
                    <div class="stat-panel bg-gradient-teal">
                        <div class="stat-cell">
                          <v-icon scale="6.6" class="bg-icon" name="globe" flip="horizontal" inverse="false" color="white"/>
                            <span class="text-xlg">{{dashboardData.row1.Websites || 0}}</span><br>
                            <span class="text-lg">Websites</span><br>
                            <router-link :to="{ name: 'Sites' }" data-ajax="/financial-overview/history">View websites</router-link>
                        </div>
                    </div>
                </div>
            </div>


            

            <div class="card my-3 border-0 bg-white ">
              <div class="card-header header-elements-inline">
                <h4 class="card-title">Order Stats (No. of orders)</h4>
              </div>
              <div class="card-body px-1 text-right d-flex">
                <line-chart
                  v-if="!loading"
                  :chartdata="data"
                  :options="options"
                  class="line-chart"
                ></line-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BalanceComponent from "../../components/payments/BalanceComponent.vue";
import { mapGetters, mapState , mapActions} from "vuex";
import LineChart from "../../components/charts/LineChart.vue";
import http from "../../modules/http.index";
import BaseLoading from "../../components/_base-loading.vue";
export default {
  name: "Dashboard",
  components: { BalanceComponent, LineChart, BaseLoading },
  computed: {
    ...mapGetters("auth", ["userType", "userTypes"]),
    ...mapState("auth", ["user"]),
    username() {
      return `${this.user.first_name || ""} ${this.user.last_name || ""}`;
    },
  },

  data() {
    return {
      loading: true,
      items: [
        {
          color: '#1F7087',
          src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
          title: 'Supermodel',
          artist: 'Foster the People',
        },
        {
          color: '#952175',
          src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
          title: 'Halcyon Days',
          artist: 'Ellie Goulding',
        },
      ],
      options: {
        maintainAspectRatio: false,
        responsive: true,
      },
      dashboardData: {},
      data: {
        labels: [],
        datasets: [],
      },
    };
  },

  methods: {
    ...mapActions("orders", ["_getClassHelpStats"]),

    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    getData() {
      this.loading = true;
      http
        .get("/System/Dashboard/")
        .then((res) => {
          this.dashboardData = res.data.Data;

          Object.entries(this.dashboardData.row2).forEach((yearData, index) => {
            let data = yearData[1].map((monthData) =>
              parseFloat(monthData.no_orders)
            );
            let labels = yearData[1].map((monthData) => monthData.month);
            this.data.labels = labels;
            let color = this.getColor(index); // get chart color based on index
            this.data.datasets.push({
              label: yearData[0], // year name e.g. 2022
              borderColor: color,
              borderWidth: "4",
              fill: false,
              pointBackgroundColor: color,
              pointRadius: "4",
              data: data,
            });
          });
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },

    getColor(index) {
      let color = "#64dfdf";
      if (index == 1) {
        color = "#48bfe3";
      } else if (index == 2) {
        color = "#5390d9";
      } else if (index == 3) {
        color = "#5e60ce";
      } else if (index == 4) {
        color = "#51b0ff";
      }
      return color;
    },
  },
  async mounted() {
    await this.getData();
    await  this._getClassHelpStats();

  },
};
</script>
<style scoped>
.line-chart {
  width: 100%;
}
</style>
